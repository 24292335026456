import { motion } from "framer-motion";
import styled from "styled-components";
import BackGroundImage from '../../assets/icons/paymentPlanIcons/background.svg'
import ExtensionBackGround from '../../assets/icons/paymentPlanIcons/ExtensionBackground.svg'

export const PricingExentsionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
  padding: 70px 0px 50px 0px;
  overflow: hidden;
  width: 100%;
  background-color: ${props => props.theme.grayBackGround};
  h1 {
    font-size: 64px;
    color: ${props => props.theme.black};
    height: 83px;
    font-weight: 700;
  }
  a {
    color: ${props => props.theme.black};
    font-weight: 500;
    line-height: 44.03px;
    text-align: center;
    margin-bottom: 51px;
    font-size: 36px;
    a {
      font-weight: 600;
      line-height: 45.25px;
      font-size: 36px;
    }
  }

  .footerButton {
    font-weight: 600;
    padding: 15px;
    font-size: 18px;
    line-height: 30px;
    color: ${props => props.theme.payementPlans.basic};
    border-radius: 8px;
    border: 0.2px solid ${props => props.theme.payementPlans.basic};
    box-shadow: 0px 4px 4px 0px #00000040;
    background-color: ${props => props.theme.white};
    z-index: 1;
    cursor: pointer;
  
    :hover {
      color: ${props => props.theme.white};
      background-color: ${props => props.theme.payementPlans.basic};
      transition: background-color 0.8s ease;
    }
  }
  
  @media screen and (max-width : 760px) {
    .footerButton {
      font-size: 15px;
      padding: 10px;
      height: 50px;
      /* background-color: red; */
    }
  }

  @media screen and (max-width : 500px) {
    .footerButton {
      font-size: 13px;
      padding: 7px;
      height: 45px;
      /* background-color: red; */
    }
  }
`

export const PricingSectionWrapper = styled(motion.div)`
  height: auto;
  display: flex;
  width: 1170px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  #title {
    font-size: 40px;
    color: ${props => props.theme.black};
    font-weight: 700;
    position: relative;
    ::after {
      transform: translate(-130%);
      content: '';
      width: 70px;
      height: 2px;
      background-color: #74acfe;
      position: absolute;
      bottom: 25px;
      right: 0;
    }
  
  }
  
  a {
    color: ${props => props.theme.black};
    font-weight: 500;
    margin-top: -20px;
    text-align: center;
    margin-bottom: 41px;
    font-size: 32px;
    padding-top: 10px;
    a {
      font-weight: 600;
      font-size: 32px;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -85px;
    left: 30%;
    width: 100%;
    height: 100%;
    background-image: url(${BackGroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 40%;
    transform: rotate(-5deg);
  }

  @media screen and (max-width: 950px){
    #title {
      font-size: 24px;
      line-height: 35px;
      height: 60px;
      margin-bottom: 30px;
      ::after {
        transform: translate(-50%);
        content: '';
        width: 70px;
        height: 2px;
        background-color: #74acfe;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    a {
      color: ${props => props.theme.black};
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      font-size: 18px;
      margin-bottom: 30px;
      a {
        font-weight: 600;
        line-height: 25px;
        font-size: 19px;
      }
    }
  }

`

export const PayementOptionContainer = styled.div`
  height: 438px;
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 50px;
  margin-bottom: 210px;
`

export const PayementOption = styled.div`
  min-width: 249px;
  max-width: 249px;
  height: 137px;
  border-radius: 24px;
  padding-top: 28px;
  background: ${props => props.color};
  text-align: center;
  h1 {
    font-weight: 700;
    font-size: 40px;
    line-height: 52.16x;
    color: white;
  }
  position: relative;
`;

export const PaymentDetails = styled.div`
  position: absolute;
  top: 108px;
  left: 50%;
  width: 229px;
  transform: translate(-50%, 0);
  border-radius: 20px;
  background-color: ${props => props.theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px 9px 15px 8px;
  gap: 0.8rem;
  box-shadow: 0px 4px 4px 0px #00000040;
  :hover {
    box-shadow: ${props => '0px 4px 4px 0px ' + props.hoverShadowColor};
  }
`;

export const Detail = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  width: 100%;
  .text {
    padding-top: 1.6px;
    font-size: 10px;
    line-height: 18px;
    font-weight: 600;
    text-align: start;
    color: ${props => props.theme.black};
  }
`;

export const BasicPaymentOptions = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .paymentPlanBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 30px;
  }

  .price {
    font-size: 32px;
    font-weight: 700;
    margin: 0px;
    color: ${props => props.color};
  }

  .devise {
    font-size: 15px;
    font-weight: 700;
    color: ${props => props.color};
    line-height: 19px;
    margin-bottom: 0px;
  }

  .freePaymentBox {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .containedBoxWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: auto;
    margin-bottom: 10px;
    h6 {
      font-size: 3px;
      line-height: 3.67px;
      color: ${props => props.theme.black};
      font-weight: 500;
    }
  }
`;

export const ContainedBox = styled.div`
  height: 25px;
  width: 117px;
  border-radius: 6px;
  text-align: center;
  color: ${props => props.theme.white};
  font-size: 14px;
  font-weight: 700;
  background-color: ${props => props.color};
  text-align: center;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 18px;
  margin-bottom: 10px;
`


export const ExtensionSectionWrapper = styled(motion.div)`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 40%;
    width: 100%;
    height: 120%;
    background-image: url(${ExtensionBackGround});
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(3deg);
  }
`

export const ExtensionsWrapper = styled.div`
  width: 1275px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
  margin-bottom: 30px;
  .Box {
    cursor: pointer;
    width: 281px;
    height: 397px;
    background-color: ${props => props.color};
    border-radius: 16px;
    color: ${props => props.theme.white};
    font-size: 24px;
    line-height: 31px;
    font-weight: 700;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }

`

export const AnimatedContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 10px;

  h1 {
    font-size: 24px;
    font-weight: 700;
    height: unset;
    line-height: 31px;
    color : ${props => props.theme.white};
    margin-bottom: 5px;
    text-align: center;
  }
`

export const ExtentionDetailsSubTitle = styled.p`
  color: ${props => props.theme.black};
  width: 100%;
  height: 60px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  line-height: 15px;
  margin-bottom: 20px;
`

export const ExtensionsDetailsBox = styled(motion.div)`
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: ${props => props.theme.white};
  overflow: hidden;
  padding: 9px;
  margin-top: 5px;

  /* p { */
  /* } */

  div {
    display: flex;
    width: 100%;
    gap: 7px;
    p {
      padding-top: 5px;
      font-weight: 600;
      font-size: 11px;
      text-align: start;
      line-height: 13px;
      letter-spacing: 3%;
      height: auto;
      color: black;
    }
  }
`

export const  PriceWrraper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  text-align: center;
  color: ${props => props.color};
  h3 {
    color: ${props => props.color};
    font-size: 32px;
    font-weight: 700;
    /* line-height: 41px; */
  }
  h6 {
    color: ${props => props.color};
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }
`

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 375px;
  padding-top: 20px;

  h2 {
    color: ${props => props.color};
    font-weight: 700px;
    font-size: 54px;
    line-height: 70.4px;
    text-transform: uppercase;
    letter-spacing: 4%;
  }

  h2:first-child {
    letter-spacing: 2px;
  }

  a {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 5%;
    text-align: left;
  }

  @media screen and (max-width : 1200px){
    align-items: center;
    /* max-width: 475px; */
    h2 {
      font-size: 40px;
      line-height: 50px;
      font-weight: 700;
      /* letter-spacing: 3px; */
    }
  
    a {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 5%;
      text-align: left;
      line-height: 28px;
      text-align: center;
    }
  }

  @media screen and (max-width : 900px){
    align-items: center;
    /* max-width: 475px; */
    h2 {
      font-size: 24px;
      height: 40px;
    }
  
    a {
      margin-top: 20px;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 5%;
      text-align: left;
      line-height: 28px;
      text-align: center;
      margin-bottom: 30px;
    }
  }

`

export const SliderWrapperStyle = styled.div`
  .slick-track{
    width: 100%;
  }

  .slick-prev {
    transform: translate(-10px,-150%);
  }
  
  .slick-next {
    transform: translate(10px,-150%);
  }
  
  button {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    color: transparent;
    padding: 15px 0px 0px 10px;
    font-size: 0px;

    &::before {
      color : ${props => props.theme.payementPlans.basic};
      padding-right: 10px;
    }

  }
  
  @media screen and (max-width: 1200px) {
    .slick-list {
      max-width: 800px;
      height: 640px;
    }
  }

  @media screen and (max-width: 900px) {
    .slick-list {
      width: 700px;
      height: 640px;
      padding-left: 45px;
    }
  }

  @media screen and (max-width: 760px) {
    .slick-list {
      width: 600px;
      height: 640px;
      padding-left: 25px;
    }
  }

  @media screen and (max-width: 560px) {
    .slick-list {
      width: 300px;
      height: 640px;
      padding-left: 25px;
    }
  }

  .slick-dots li.slick-active button:before{
    color : ${props => props.theme.payementPlans.basic};
  }
`

export const AnimatedBoxSliderWrapper = styled.div`
  margin-bottom: 33px;
  .slick-prev {
    transform: translate(-10px,-150%);
  }
  
  .slick-next {
    transform: translate(10px,-150%);
  }
  
  button {
    /* display: none !important; */
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    color: transparent;
    padding: 15px 0px 0px 10px;
    font-size: 0px;

    &::before {
      color : ${props => props.theme.extensionPrimaryColor};
      padding-right: 10px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .slick-list {
      width: 283px;
    }

    button {
      display: block !important;
    }
    
    .slick-prev {
      transform: translate(-30px,-50%);
    }
    
    .slick-next {
      transform: translate(30px,-50%);
    }
  }
  
  @media screen and (min-width: 760px) {
    .slick-list {
      width: 80vh;
      padding-left: 30px;
    }
    
    .slick-prev {
      transform: translate(0,-50%);
    }
    
    .slick-next {
      transform: translate(0,-50%);
    }
  }

  @media screen and (min-width: 950px) {
    .slick-list {
      width: 110vh;
      /* background-color: red; */
      /* height: 700px; */
      /* padding-left: 70px; */
    }
  }

  @media screen and (min-width: 1200px) {
    .slick-list {
      /* background-color: red; */
      width: 1200px;
      height: 700px;
      /* padding-left: 25px; */
    }
  }

  .slick-dots li.slick-active button:before{
    color : ${props => props.theme.extensionPrimaryColor};
  }

`